.navbar {
  background-color: #261580;
  font-size: 1.4rem;
  font-family: "Bebas Neue", cursive;
}

.navbar-brand {
  font-size: 1.7rem !important;
}

.logo {
  background-color: #fff;
  color: #fff;
}

#cartasModal {
  background-color: #37306b;
  color: black;
}

.navbar-collapse {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

.navbar-collapse.collapsing {
  height: 0;
}

.navbar-collapse.show {
  height: auto;
}
