@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Chivo:wght@400;500&display=swap");

.App {
  background-color: #ecf2ff;
  color: #261580;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.header-sticky.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
