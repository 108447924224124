.phrases-container {
  background-color: #261580;
  color: #fff;
  padding-top: 2rem;
  padding-bottom: 4rem;
  text-align: center;
}

.phrases-title {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  position: relative;
  font-family: "Bebas Neue", cursive;
}

.phrases-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  padding: 20px;
}

.phrase {
  font-size: 2rem;
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding: 4rem;
}

.short {
  width: 50%;
}

@media (max-width: 768px) {
  .phrases-title {
    text-align: center;
    font-size: 3rem;
  }

  .phrase {
    font-size: 1.6rem;
    width: 100%;
  }
}
