.aboutme-container {
  background-color: #261580;
  color: #fff;
}

.aboutme-title {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  position: relative;
  font-family: "Bebas Neue", cursive;
}

.aboutme-description {
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;

  margin-top: 2rem;
  margin-bottom: 4rem;
  position: relative;
}

.aboutme-image {
  width: 60%;
  border: 3px solid #333; /* adds a border */
  background-color: #f2f2f2; /* sets a background color */
  padding: 20px; /* adds some padding inside the figure */
  margin: 30px 0;
  margin-left: auto;
  margin-right: auto;
  /* adds some margin around the figure */
}

@media (max-width: 768px) {
  .aboutme-image {
    width: 80%;
    /* Positionate the image in the center */
    display: block;
    margin-top: -1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutme-title {
    text-align: center;
    font-size: 3rem;
  }

  .aboutme-description {
    width: 90%;
    display: flex;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
