.services {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.services-title {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
  text-align: right;
  font-family: "Bebas Neue", cursive;
}

.services-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 20px;
}

.services-cards {
  background-color: #261580;
  color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.modal-description {
  width: 90%;
  margin: 1rem;
  margin-right: auto;
  font-size: 1.2rem;
  color: #261580;
}

.modal-title {
  color: #261580;
  font-size: 1.4rem;
}

.btn-light {
  margin-left: 1rem;
}

.healy {
  color: #261580;
  text-decoration: none;
}

@media (max-width: 768px) {
  .services-title {
    font-size: 3rem;
    text-align: center;
  }

  .services-cards {
    width: 75%;
  }
}
