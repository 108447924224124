footer {
  text-align: center;
  justify-content: center;
}

.footer-container {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.footer-link:hover {
  cursor: pointer;
}

.heart-icon {
  color: #e1306c;
  width: 1rem;
}
