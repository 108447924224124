.form-container {
  padding: 2rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  background-color: #fff;
  color: #7f6aad;
  border-radius: 20px;
}

.year-title {
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

h3 {
  color: #7f6aad !important;
  font-weight: bold;
  margin-bottom: 1rem;
}

#calculator {
  background-color: #261580;
  color: #ecf2ff;
  padding: 4rem 0;
}

.calculator-title {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 1.5rem;
  position: relative;
  text-align: center;
  font-family: "Bebas Neue", cursive;
}

.calculator-description {
  width: 90%;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.year-description {
  font-size: 1.5rem;
  background-color: #fff;
  color: #261580;
  border-radius: 20px;
  padding: 2rem;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  border-radius: 0.25rem;
  font-size: 1.2rem;
  background-color: #f2f2f2;
  width: 70%;
  border-radius: 10px;
}

.month-input {
  margin-bottom: 2rem;
}

.label-input {
  font-size: 1.5rem;
  font-weight: bold;
}

.calculator-button {
  font-size: 1rem !important;
  font-weight: bold !important;
  background-color: #ff6000 !important;
  color: white !important;
  border: none !important;
  border-radius: 10px !important;
  font-weight: bold !important;
  padding: 1rem 2rem !important;
  margin-top: 2rem !important;
  text-transform: uppercase !important;
}

.calculator-button:hover {
  background-color: #b8621b !important;
}

.result-span {
  font-weight: bold;
  text-decoration: none;
}

.arrow-up {
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  animation: arrow-up 1s ease-in-out infinite;
}

.arrow-container {
  width: 100%;
  text-align: center;
  margin-bottom: -2rem;
}

@keyframes arrow-up {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .year-description {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    font-size: 1.3rem;
    width: 90%;
  }

  .form-control {
    font-size: 1.1rem;
    width: 100%;
  }

  .year-title {
    text-align: left;
  }

  .calculator-description {
    font-size: 1.3rem;
    text-align: left;
  }
}
