@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Chivo:wght@400;500&display=swap");

.homepage {
  margin-top: 6rem;
}

.homepage-title {
  font-size: 6rem;
  font-weight: 700;
  /* color: #33217a; */
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-top: 2rem;
  position: relative;
  font-family: "Bebas Neue", cursive;
}

h2 {
  color: #7f6aad !important;
  margin-top: 20px;
  font-size: 2.4rem;
  font-family: "Bebas Neue", cursive;
}

span {
  font-weight: bold;
  text-decoration: underline;
}

.text-description {
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  margin-bottom: 4rem;
}

.profile-image {
  border-radius: 50%;
  width: 90%;
  margin: 30px 0 50px 0;
}

.social-container {
  display: flex;
  gap: 40px;
  margin-top: 30px;
}

.instagram-icon {
  width: 4rem;
}

.instagram-icon:hover {
  color: #e1306c;
}

.facebook-icon {
  width: 4rem;
}

.facebook-icon:hover {
  color: #3b5998;
  animation: facebook 1s infinite;
}

@media (max-width: 768px) {
  .profile-image {
    width: 80%;
  }

  .homepage-title {
    margin-top: 30px;
    text-align: center;
    font-size: 5rem;
  }

  .social-container {
    align-items: start;
  }

  h2 {
    width: 80%;
    margin-top: 1.5rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .text-description {
    margin-top: 1.5rem;
    width: 90%;
    display: flex;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
