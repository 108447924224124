.contact-container {
  background-color: #ecf2ff;
}

.contact-title {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  position: relative;
  text-align: center;
  font-family: "Bebas Neue", cursive;
}

.social-container-contact {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 30px;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.whatsapp-icon-contact {
  color: #25d366;
  width: 6rem;
}

.whatsapp-icon-contact:hover {
  color: #128c7e;
}

.facebook-icon-contact {
  color: #3b5998;
  width: 6rem;
}

.facebook-icon-contact:hover {
  color: #2d4373;
}

.instagram-icon-contact {
  color: #e1306c;
  width: 6rem;
}

.instagram-icon-contact:hover {
  color: #c13584;
}

@media (max-width: 768px) {
  .contact-title {
    font-size: 3rem;
    text-align: center;
  }

  .whatsapp-icon-contact,
  .facebook-icon-contact,
  .instagram-icon-contact {
    width: 4.5rem;
  }

  .social-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
